import React, { useEffect, useState } from "react";
import bg_image from "../assets/images/image_processing20210908-20998-13yca0v.gif";
import rock from "../assets/images/rock.png";
import Planet1 from "../assets/images/Planet1.png";
import Planet2 from "../assets/images/Planet2.png";
import comet from "../assets/images/Comet.png";
import Card from "../components/Card/Card";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useProvider, useSigner } from "wagmi";
import { ethers } from "ethers";
import { Notyf } from "notyf";
import stakingAbi from "../Abi/stakingAbi.json";
import tokenAbi from "../Abi/tokenAbi.json";
import "notyf/notyf.min.css";
import "./HomePage.css";
import value from "../value.json";
import axios from "axios";
import moment from "moment/moment";

window.ethers = ethers;

const HomePage = () => {
  const notyf = new Notyf({
    duration: 5000,
    position: { x: "right", y: "top" },
    dismissible: true,
    types: [
      {
        type: "error",
        background: "black", // Background color for error notification
        color: "white", // Text color for error notification
      },
    ],
  });

  const { isDisconnected } = useAccount();
  const [stakingAddress, setStakingAddress] = useState(value.stakingAddress1);
  const { data: signer } = useSigner();
  const provider = useProvider();
  const [poolId, setPoolId] = useState("");
  const [myStakeBalance, setMyStakeBalance] = useState(0);
  const [myTokenBalance, setMyTokenBalance] = useState(0);
  const [emergencyFee, setEmergencyFee] = useState(0);
  const [lockDeadline, setLockDeadline] = useState(0);
  const [isTokenApproved, setIsTokenApproved] = useState(false);
  const [amount, setAmount] = useState("");
  const [poolInp, setPoolInp] = useState("");
  const [claimableRewards, setClaimableRewards] = useState(0);
  const [stakeHolders, setStakeHolders] = useState(0);
  const [totalTokensLocked, setTotalTokensLocked] = useState(0);
  const [tokenValue, setTokenValue] = useState(0);
  const [unstake, setunstake] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [multiplier, setMultiplier] = useState(0);
  const [poolInfo, setPoolInfo] = useState(0);

  const staking = new ethers.Contract(stakingAddress, stakingAbi, signer ? signer : provider);
  const token = new ethers.Contract(value.stakingToken, tokenAbi, signer);

  let rpcUrl = value.rpcUrl;
  let provider_ = new ethers.providers.JsonRpcProvider(rpcUrl);
  const staking2 = new ethers.Contract(stakingAddress, stakingAbi, provider_);

  function refreshData(signer) {
    getPoolInfo();
    if (signer) {
      getUserInfo();
      getTokenBalance();
      checkApproved();
    }
  }

  async function getPoolInfo() {
    console.log("poolid", poolId);
    try {
      const token2 = new ethers.Contract(value.stakingToken, tokenAbi, provider_);
      let _poolInfo = await staking2.poolInfo(poolId);
      const decimals = await token2.decimals();
      const stakeHolders = await _poolInfo.stakeHolders;
      const totalTokensLocked = await _poolInfo.currentPoolSize;
      // const apy = await staking2.getApy(poolId);
      // setApy(apy.toString() + `%`);
      setPoolInfo(_poolInfo);
      setEmergencyFee(_poolInfo.emergencyFees.toString() + `%`);
      setStakeHolders(stakeHolders.toString());
      setTotalTokensLocked(ethers.utils.formatUnits(totalTokensLocked.toString(), decimals));
      let data = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/0x6706e05F3BAFdbA97dE268483BC3a54bf92A883C`);
      // let calculatedValue = 0;
      // data = data.data;
      try {
        // const base = data.pairs[0].liquidity.base;
        // const priceUsd = parseFloat(data.pairs[0].priceUsd);
        // const quote = data.pairs[0].liquidity.quote;
        // const liquidity = data.pairs[0].liquidity.usd;
        // calculatedValue = (liquidity - base * priceUsd) / quote;
        setTokenValue(Number(data?.data?.pairs[0]?.priceUsd).toFixed(2));
      } catch (error) {
        setTokenValue(0);
      }
    } catch (err) {
      console.dir(err);
    }
  }

  async function getUserInfo() {
    try {
      let userAddress = await signer.getAddress();
      let _userInfo = await staking.userInfo(poolId, userAddress);
      const decimals = await token.decimals();
      const myStakeBalance = ethers.utils.formatUnits(_userInfo.amount.toString(), decimals);
      setMyStakeBalance(myStakeBalance);

      // if (myStakeBalance == 3) {
      //   setMyStakeBalance(3);
      //   setLockDeadline('NA');
      //   return;
      // }

      const lockDeadlineTimestamp = await staking.getUserLockTime(poolId, userAddress);
      const lockDeadlineDate = Number(lockDeadlineTimestamp.toString() + "000");
      const date = new Date(lockDeadlineDate);
      if (date.getFullYear() < 2023) {
        setLockDeadline("NA");
        return;
      }
      const deadline = moment(lockDeadlineDate).format("DD-MM-YYYY HH:mm");
      setLockDeadline(deadline);
    } catch (err) {
      console.dir(err);
    }
  }

  async function getTokenBalance() {
    try {
      let userAddress = await signer.getAddress();
      const tokenbalance = await token.balanceOf(userAddress);
      const decimals = await token.decimals();
      const tokenbalanceConverted = ethers.utils.formatUnits(tokenbalance.toString(), decimals);
      setMyTokenBalance(Math.floor(tokenbalanceConverted));
    } catch (err) {
      console.log("getTokenBalance", err.message);
    }
  }

  async function checkApproved() {
    try {
      const userAddress = await signer.getAddress();
      const isApproved = await token.allowance(userAddress, stakingAddress);
      const totaltokenapproved = isApproved.toString();
      if (totaltokenapproved.length > 12) {
        setIsTokenApproved(true);
      } else {
        setIsTokenApproved(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function approve() {
    if (!isTokenApproved) {
      try {
        let _amount = ethers.utils.parseUnits("10000000000000000", 9);
        let tx = await token.approve(stakingAddress, _amount);
        let reciept = await tx.wait();
        console.log("Approve Tx Receipt: ", reciept);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Already approved");
    }
  }

  async function stakeTokens() {
    if (unstake) {
      setunstake(false);
      return;
    }
    if (amount === 0) {
      notyf.error("Enter Stake Amount");
      return;
    }
    setIsLoading(true);
    try {
      await approve();
      let tempDecimals = await token.decimals();
      let _amount = ethers.utils.parseUnits(amount.toString(), tempDecimals);
      let tx = await staking.stakeTokens(poolId, _amount);
      let reciept = await tx.wait();
      console.log("Stake Tx Receipt: ", reciept);
      notyf.success("Staked Successfully and claimable rewards are sent to your wallet address if any");
      refreshData(signer);
    } catch (err) {
      console.dir(err);
      try {
        notyf.error(err.error.data.message);
      } catch {
        notyf.error("Oops! Something unexpected happened. Please try again later!");
      }
    }
    setIsLoading(false);
  }

  async function unstakeTokens() {
    if (!unstake) {
      setunstake(true);
      return;
    }
    if (myStakeBalance <= 0) {
      notyf.error(`You dont have any stake token !`);
      return;
    }
    if (myStakeBalance <= 0) {
      notyf.error(`You dont have any stake token !`);
      return;
    }
    // if (poolId) {
    //   notyf.error('Enter Pool Id');
    //   return;
    // }
    setIsLoading(true);
    try {
      // const options = {
      //   gasPrice: ethers.utils.parseUnits('10', 'gwei')
      // }
      let tx = await staking.unstakeTokens(poolId);
      let reciept = await tx.wait();
      console.log("Unstake Tx Receipt: ", reciept);
      notyf.success("Unstaked Successfully");
      refreshData(signer);
    } catch (err) {
      notyf.error(err.reason);
    }
    setIsLoading(false);
  }

  async function emergencyWithdraw() {
    setIsLoading(true);
    try {
      // const options = {
      //   gasPrice: ethers.utils.parseUnits('10', 'gwei')
      // }
      const tx = await staking.emergencyWithdraw(poolId);
      await tx.wait();
      console.log("Emergency withdrawal successful:", tx.hash);
      notyf.success("Emergency Withdraw Successfully");
      refreshData(signer);
    } catch (error) {
      notyf.error(error?.reason || "Error during emergency withdrawal");
      console.log(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        let _poolsize = await staking2.poolLength();
        console.log("poolsize", _poolsize.toString());
        setPoolId(_poolsize.toString() - 1);
        refreshData(signer);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, [signer, poolId, stakingAddress]);

  useEffect(() => {
    try {
      setClaimableRewards(ethers.utils.formatUnits(ethers.utils.parseUnits(myTokenBalance.toString(), 9).mul(poolInfo.rewardAmount).div(poolInfo.currentPoolSize), 6));
    } catch (error) {
      console.log(error);
    }
  }, [poolInfo]);

  return (
    <>
      <div className="bg-image">
        <img className="rock-1" src={rock} alt="rock" />
        <img className="rock-2" src={rock} alt="rock" />
        <img className="rock-3" src={rock} alt="rock" />
        <img className="rock-4" src={rock} alt="rock" />
        <img className="rock-5" src={rock} alt="rock" />
        <img className="rock-6" src={rock} alt="rock" />
        <img className="planet-1" src={Planet1} alt="Planet1" />
        <img className="planet-2" src={Planet2} alt="Planet2" />
      </div>
      <div>
        <div className="main-container">
          <div className="token-container-wrapper">
            <div className="upper-box-group">
              <Card heading={"Total Value Locked"} value={totalTokensLocked} />
              <Card heading={"Stake holders"} value={stakeHolders} />
            </div>

            <div className="left-box-group">
              <Card heading={"My Balance"} value={myTokenBalance} />
              <Card heading={"My Staked Balance"} value={myStakeBalance} />
              <Card heading={"Lock Deadline"} value={lockDeadline} />
            </div>
            <div className="right-box-group">
              <Card heading={"Early Unstake Fee"} value={emergencyFee} />
              <Card heading={"APY Rate"} value={"Dynamic"} />
              <Card heading={"Claimable Rewards"} value={claimableRewards} />
            </div>

            <div className="token-container">
              {isLoading && (
                <div className="loadingImgDiv">
                  <img src={bg_image} className="loadingImg" alt="" />
                </div>
              )}
              <h2 className="clr-green letter-spacing-1">Token Value</h2>
              <h2 className="clr-white">${tokenValue}</h2>
              <h6 className="clr-green token-note letter-spacing-1">*For staking, minimum lock period is 30 days</h6>
              <div className="pol-id">
                <span className="clr-green">Pool ID</span>
                <span className="clr-white">{poolId}</span>
              </div>
              <div className="note">
                <span className="clr-white">Note - </span>
                <span className="clr-green letter-spacing-1"> Remember the Pool ID*</span>
              </div>

              <div className="pol-creater-wrapper">
                <div className="payment-option-container">
                  {unstake ? (
                    <>
                      <label htmlFor="">Enter pool Id</label>
                      <input type="number" value={poolInp} placeholder="Enter pool Id" onChange={(e) => setPoolInp(e.target.value)} />
                    </>
                  ) : (
                    <>
                      <label htmlFor="">Enter Stake Amt</label>
                      <input type="number" value={amount} placeholder="0.00" onChange={(e) => setAmount(e.target.value)} />
                    </>
                  )}
                  <div className="payment-opt-btn-group amount">
                    <button onClick={() => setAmount(myTokenBalance * 0.25)}>25%</button>
                    <button onClick={() => setAmount(myTokenBalance * 0.5)}>50%</button>
                    <button onClick={() => setAmount(myTokenBalance * 0.75)}>75%</button>
                    <button onClick={() => setAmount(myTokenBalance)}>100%</button>
                  </div>
                  <div className="payment-opt-btn-group">
                    <button onClick={stakeTokens}>Stake</button>
                    <button onClick={emergencyWithdraw}>Withdraw</button>
                    <button onClick={unstakeTokens}>Unstake</button>
                  </div>
                  {isDisconnected && (
                    <div className="connect-wallet-btn">
                      <ConnectButton chainStatus="none" />
                    </div>
                  )}
                </div>
              </div>

              <div className="comet-img">
                <img src={comet} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
