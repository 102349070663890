import React from 'react'
import logo from '../../assets/images/logo.png'
import { IoMenu, IoClose } from 'react-icons/io5'
import './Navbar.css'

const Navbar = ({ ConnectButton }) => {
     return (
          <header className='header'>
               <nav className='nav'>
                    <div className="left-nav">
                         <label className="hamberger-menu" >
                              <input type="checkbox" aria-label="hamberger-menu" id='hamberger-checkbox' />
                              <IoMenu />
                         </label>
                         <div className="menu-option">
                              <label htmlFor='hamberger-checkbox' className='close-menu-btn'>
                                   <IoClose />
                              </label>
                              <span className='clr-white mob-home' onClick={()=>window.open('https://hype-eth.com/#', '_blank')}>Home</span>
                         </div>

                         <div className="logo"  onClick={()=>window.open('https://hype-eth.com/#', '_blank')}>
                              <img src={logo} alt="Logo" className="logo-image" />
                              <span className="logo-text">HYPE STAKING</span>
                         </div>
                    </div>
                    <div className="menu">
                         <span className='home' onClick={()=>window.open('https://hype-eth.com/#', '_blank')}>Home</span>
                         <ConnectButton chainStatus='none' />
                    </div>
               </nav>
          </header>
     )
}

export default Navbar