import React from 'react'
import './Footer.css'
import { BsTwitter, BsDiscord } from 'react-icons/bs'
import { IoSend } from 'react-icons/io5'

const Footer = () => {
     return (
          <div className='footer'>
               <h2 className="logo-text">
                    HYPE STAKING
               </h2>
               <p className="copyright">
                    Copyright © 2023. All Rights Reserved by Hype
               </p>
               <div className="social-links">
                    <BsTwitter onClick={() => window.open('https://twitter.com/i/flow/login?redirect_after_login=%2Fhypeerc20', '_blank')} />
                    <IoSend onClick={() => window.open('https://t.me/TeleMixerBot', '_blank')} />
                    <BsDiscord />
               </div>
          </div>
     )
}

export default Footer